(function($){
	'use strict';
	window.skafold = $.extend(true, {}, window.skafold || {}, {

		$body: $('body'),
		mobileWidth: 768,

		init: function() {

			this.mobileMenu();
			this.validation();
			this.voteQuery();

			if ( this.$body.width() >= this.mobileWidth ) {
				this.equalHeights();
				this.centerVertically();
			}
		},

		/*
		 *	Equal height
		 */
		equalHeights: function() {
			var ah = $('.award .award-head'),
				ab = $('.award .award-body'),
				af = $('.award .award-foot'),
				eh = $('.eligibility .eligibility-head'),
				eb = $('.eligibility .eligibility-body'),
				nh = $('.nominee .nominee-head'),
				nb = $('.nominee .nominee-body');

			if (ah.length) { this.equaliseHeight(ah); }
			if (ab.length) { this.equaliseHeight(ab); }
			if (af.length) { this.equaliseHeight(af); }
			if (eh.length) { this.equaliseHeight(eh); }
			if (eb.length) { this.equaliseHeight(eb); }
			if (nh.length) { this.equaliseHeight(nh); }
			if (nb.length) { this.equaliseHeight(nb); }
		},


		equaliseHeight: function( $collection ) {
			var maxHeight = 0;
			$collection.each( function(i,el){
				maxHeight = Math.max( $(el).height(), maxHeight );
			} );
			$collection.height(maxHeight);
		},

		centerVertically: function() {
			var blocks = $('.left-right-blocks .block');

			blocks.each(function(i,blobk){
				var $b = $(this),
					$c = $('.content', $b);

				if ( $c.height() < $b.height() ) {
					$c.css({'margin-top': (($b.height() - $c.height()) / 2) });
				}

			});

		},

		validation: function() {
			var self = this;
			this.$body
				.on('keyup change', '.gfield_error input[type="text"], .gfield_error select, .gfield_error textarea', function(e){
					if ($(this).val() !== '') {
						$(this).closest('.gfield_error').removeClass('gfield_error');
					}
				});
		},

		/*
		 *	Mobile menu
		 */
		mobileMenu: function() {
			var self = this;

			this.$body
				.on('click', 'header .menu-toggle', function(e){
					e.preventDefault();
					self.$body.toggleClass('mobile-menu-open');
				})
				.on('click', 'header .menu-close', function(e){
					e.preventDefault();
					self.$body.removeClass('mobile-menu-open');
				});

		},

		voteQuery: function() {
			var self = this;

			$( document ).ready(function() {

				var $votingForContainer = $("#voting_for");
				var $votingForInput = $(".voting-for").find("textarea").val();
				var $text;
				var re = new RegExp(/^.*\//);

				if ($votingForInput == '') {
					$text = "<div class=\"to-award-error\"><strong>Error! Please return to the voting page </strong></div>";
				} else {
					$text = "You are submitting a vote: <strong class=\"to-award\">";
					$text = $text + $votingForInput;
					$text = $text + "</strong>";
				}


				$votingForContainer.html($text);

			});
		},


	});

	// $(document).on("click",".nominating-click .gfield_checkbox .gform-field-label",function() {
	// 	$(this).toggleClass('toggle-active');
	// 	$(this).parents('.nominating-click').toggleClass('clicked-active');
	// });

})(jQuery);

jQuery(document).ready(function(){
  skafold.init();
});

